import { App as CapacitorApp } from "@capacitor/app";
import { Dialog } from "@capacitor/dialog";
import { Preferences } from "@capacitor/preferences";
import React, { useEffect, useState } from "react";
import ReactGA4 from "react-ga4";
import { Helmet } from "react-helmet";
import { clarity } from "react-microsoft-clarity";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "tailwindcss/tailwind.css";
import Bienvenido from "./components/Bienvenido";
import MarkmapSchemaCreator from "./components/ConceptualMaps";
import EssayCreator from "./components/EssayCreator";
import ExamCorrector from "./components/ExamCorrector";
import ExamList from "./components/ExamList";
import ExamSolver from "./components/ExamSolver";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Highlight from "./components/Highlight";
import Login from "./components/Login";
import ModalNoToken from "./components/ModalNoToken";
import NoteTaker from "./components/NoteTaker";
import QuizFile from "./components/QuizFile";
import {
  SubscriptionProvider,
  useSubscription,
} from "./components/SubscriptionContext";
import Summary from "./components/Summary";
import ThankYou from "./components/ThankYou";
import { TutorsGallery } from "./components/TutorsGallery";
import useAuth from "./components/useAuthToken";
import { useLanguage } from "./components/useLanguaje";
import UserProfile from "./components/UserProfile";
import VerPrecios from "./components/VerPrecios";

const TRACKING_ID = "G-6FQ7N7HHNB"; // Replace with your Google Analytics 4 tracking ID

const PageTracker = () => {
  const { subscriptionType } = useSubscription();
  const location = useLocation();
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      loginTitle: "Inicio sesión",
      loginMessage: "Has iniciado sesión correctamente",
      pageNotFound: "Página no encontrada",
    },
    en: {
      loginTitle: "Login",
      loginMessage: "You have successfully logged in",
      pageNotFound: "Page not found",
    },
    it: {
      loginTitle: "Accesso",
      loginMessage: "Hai effettuato l'accesso con successo",
      pageNotFound: "Pagina non trovata",
    },
  };

  CapacitorApp.addListener("appUrlOpen", (data) => {
    console.log("App opened with URL:", data?.url);
    const url = new URL(data?.url);
    const token = url.searchParams.get("token");
    if (token) {
      Preferences.set({
        key: "token",
        value: token,
      }).then(() => {
        console.log("Token saved");
        Dialog.alert({
          title: texts[currentLanguage].loginTitle,
          message: texts[currentLanguage].loginMessage,
        });
        window.location.href = "/";
      });
    }
  });

  useEffect(() => {
    clarity.init("o66nvqjnct");
  }, []);

  useEffect(() => {
    ReactGA4.initialize(TRACKING_ID);
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
    if (localStorage.getItem("email")) {
      if (window.plausible) {
        window.plausible("pageview", {
          props: {
            path: location.pathname + location.search,
            //email: localStorage.getItem("email"),
          },
        });
      }
    } else if (window.plausible) {
      window.plausible("pageview", {
        props: { path: location.pathname + location.search },
      });
    }
  }, [location]);

  useEffect(() => {
    const showModalAfterTimeout = () => {
      const modalShown = localStorage.getItem("modalShown");
      const token = localStorage.getItem("token");
      if (!modalShown && token && subscriptionType !== "No Subscription") {
        setTimeout(() => {
          document.getElementById("my_modal_3").showModal();
          localStorage.setItem("modalShown", "true");
        }, 240000); // 4 minutos
      }
    };

    if (subscriptionType !== null) showModalAfterTimeout();
  }, [subscriptionType]);

  return null; // This component does not render anything
};

function HandleNoToken({ showNoTokenModal, setShowNoTokenModal }) {
  const [token, clearToken] = useAuth();
  useEffect(() => {
    if (token === null && showNoTokenModal) {
      setShowNoTokenModal(true);
    }
  }, [showNoTokenModal, setShowNoTokenModal, token]);
  return (
    showNoTokenModal && (
      <ModalNoToken setShowNoTokenModal={setShowNoTokenModal} />
    )
  );
}

function App() {
  const [showNoTokenModal, setShowNoTokenModal] = useState(false);
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      pageNotFound: "Página no encontrada",
    },
    en: {
      pageNotFound: "Page not found",
    },
    it: {
      pageNotFound: "Pagina non trovata",
    },
  };

  const routes = {
    es: {
      home: "/",
      viewPrices: "/ver-precios",
      welcome: "/bienvenida",
      correctExam: "/corregir-examen-con-ia",
      noteTaking: "/tomar-apuntes-con-ia",
      highlightText: "/subrayar-texto-con-ia",
      createSummary: "/crear-resumen-con-ia",
      createDiagrams: "/crear-esquemas-con-ia",
      tutors: "/tutores-ia",
      interactiveQuiz: "/cuestionario-interactivo-ia",
      history: "/historial",
      profile: "/perfil",
      login: "/iniciar-sesion",
      createEssay: "/crear-ensayo",
      thankYou: "/gracias",
    },
    en: {
      home: "/",
      viewPrices: "/view-prices",
      welcome: "/welcome",
      correctExam: "/correct-exam-with-ai",
      noteTaking: "/note-taking-with-ai",
      highlightText: "/highlight-text-with-ai",
      createSummary: "/create-summary-with-ai",
      createDiagrams: "/create-diagrams-with-ai",
      tutors: "/tutors-ai",
      interactiveQuiz: "/interactive-quiz-ai",
      history: "/history",
      profile: "/profile",
      login: "/login",
      createEssay: "/create-essay",
      thankYou: "/thank-you",
    },
    it: {
      home: "/",
      viewPrices: "/vedi-prezzi",
      welcome: "/benvenuto",
      correctExam: "/correggi-esame-con-ia",
      noteTaking: "/prendere-appunti-con-ia",
      highlightText: "/evidenziare-testo-con-ia",
      createSummary: "/creare-riassunto-con-ia",
      createDiagrams: "/creare-schemi-con-ia",
      tutors: "/tutori-ia",
      interactiveQuiz: "/quiz-interattivo-ia",
      history: "/cronologia",
      profile: "/profilo",
      login: "/accedi",
      createEssay: "/creare-saggio",
      thankYou: "/grazie",
    },
  };

  return (
    <Router>
      <SubscriptionProvider>
        <Helmet>
          <html lang={currentLanguage} />
          <title>
            {currentLanguage === "es"
              ? "FotoExamen: Aprende con IA 📸📚"
              : currentLanguage === "it"
              ? "RisolviCompiti: Impara con IA 📸📚"
              : "PhotoExamaI: Learn with AI 📸📚"}
          </title>
          <meta
            name="description"
            content={
              currentLanguage === "es"
                ? "FotoExamen Resuelve tareas y examenes con solo una foto"
                : currentLanguage === "it"
                ? "RisolviCompiti Risolve compiti ed esami con una sola foto"
                : "PhotoExamAI Solves tasks and exams with just a photo"
            }
          />
          <meta
            property="og:title"
            content={
              currentLanguage === "es"
                ? "FotoExamen: Aprende con IA 📸📚"
                : currentLanguage === "it"
                ? "RisolviCompiti: Impara con IA 📸📚"
                : "PhotoExamaI: Learn with AI 📸📚"
            }
          />
          <meta
            property="og:description"
            content={
              currentLanguage === "es"
                ? "FotoExamen Resuelve tareas y examenes con solo una foto"
                : currentLanguage === "it"
                ? "RisolviCompiti Risolve compiti ed esami con una sola foto"
                : "PhotoExamAI Solves tasks and exams with just a photo"
            }
          />
          <meta
            property="og:image"
            content={
              currentLanguage === "es"
                ? "https://app.fotoexamen.com/preview.png"
                : currentLanguage === "it"
                ? "https://app.risolvicompiti.it/preview.png"
                : "https://app.photoexamai.com/preview.png"
            }
          />
          <meta
            property="og:url"
            content={
              currentLanguage === "es"
                ? "https://app.fotoexamen.com"
                : currentLanguage === "it"
                ? "https://app.risolvicompiti.it"
                : "https://app.photoexamai.com"
            }
          />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={
              currentLanguage === "es"
                ? "FotoExamen: Aprende con IA 📸📚"
                : currentLanguage === "it"
                ? "RisolviCompiti: Impara con IA 📸📚"
                : "PhotoExamaI: Learn with AI 📸📚"
            }
          />
          <meta
            name="twitter:description"
            content={
              currentLanguage === "es"
                ? "FotoExamen Resuelve tareas y examenes con solo una foto"
                : currentLanguage === "it"
                ? "RisolviCompiti Risolve compiti ed esami con una sola foto"
                : "PhotoExamAI Solves tasks and exams with just a photo"
            }
          />
          <meta
            name="twitter:image"
            content={
              currentLanguage === "es"
                ? "https://app.fotoexamen.com/preview.png"
                : currentLanguage === "it"
                ? "https://app.risolvicompiti.it/preview.png"
                : "https://app.photoexamai.com/preview.png"
            }
          />

          <script
            defer
            data-domain={
              currentLanguage === "en"
                ? "app.photoexamai.com"
                : currentLanguage === "it"
                ? "app.risolvicompiti.it"
                : "app.fotoexamen.com"
            }
            src={`https://analytics.fotoexamen.com/js/script.js`}
          ></script>
          <script>{`
            window.plausible = window.plausible || function () {
              (window.plausible.q = window.plausible.q || []).push(arguments);
            }
          `}</script>
        </Helmet>
        <HandleNoToken
          showNoTokenModal={showNoTokenModal}
          setShowNoTokenModal={setShowNoTokenModal}
        />
        <div className="App">
          <Header>
            <Routes>
              <Route
                path={routes[currentLanguage].home}
                element={
                  <>
                    <PageTracker />
                    <ExamSolver setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].viewPrices}
                element={
                  <>
                    <PageTracker />
                    <VerPrecios />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].welcome}
                element={
                  <>
                    <PageTracker />
                    <Bienvenido setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].correctExam}
                element={
                  <>
                    <PageTracker />
                    <ExamCorrector setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].noteTaking}
                element={
                  <>
                    <PageTracker />
                    <NoteTaker setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].highlightText}
                element={
                  <>
                    <PageTracker />
                    <Highlight setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].createSummary}
                element={
                  <>
                    <PageTracker />
                    <Summary setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].createDiagrams}
                element={
                  <>
                    <PageTracker />
                    <MarkmapSchemaCreator
                      setShowNoTokenModal={setShowNoTokenModal}
                    />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].tutors}
                element={
                  <>
                    <PageTracker />
                    <TutorsGallery setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].interactiveQuiz}
                element={
                  <>
                    <PageTracker />
                    <QuizFile setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path="/quizz"
                element={
                  <Navigate
                    to={routes[currentLanguage].interactiveQuiz}
                    replace
                  />
                }
              />
              <Route
                path="/quiz"
                element={
                  <Navigate
                    to={routes[currentLanguage].interactiveQuiz}
                    replace
                  />
                }
              />
              <Route
                path="/apuntes"
                element={
                  <Navigate
                    to={routes[currentLanguage].noteTaking}
                    replace
                  />
                }
              />
              <Route
                path="/esquemas"
                element={
                  <Navigate
                    to={routes[currentLanguage].createDiagrams}
                    replace
                  />
                }
              />
              <Route
                path="/tutores"
                element={
                  <Navigate
                    to={routes[currentLanguage].tutors}
                    replace
                  />
                }
              />
              <Route
                path="/subrayar"
                element={
                  <Navigate
                    to={routes[currentLanguage].highlightText}
                    replace
                  />
                }
              />
              <Route
                path="/resumen"
                element={
                  <Navigate
                    to={routes[currentLanguage].createSummary}
                    replace
                  />
                }
              />
              <Route
                path="/corregir"
                element={
                  <Navigate
                    to={routes[currentLanguage].correctExam}
                    replace
                  />
                }
              />
              <Route
                path={routes[currentLanguage].history}
                element={
                  <>
                    <PageTracker />
                    <ExamList
                      userEmail="user@example.com"
                      setShowNoTokenModal={setShowNoTokenModal}
                    />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].profile}
                element={
                  <>
                    <PageTracker />
                    <UserProfile setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].login}
                element={
                  <>
                    <PageTracker />
                    <Login />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].createEssay}
                element={
                  <>
                    <PageTracker />
                    <EssayCreator setShowNoTokenModal={setShowNoTokenModal} />
                  </>
                }
              />
              <Route
                path={routes[currentLanguage].thankYou}
                element={
                  <>
                    <PageTracker />
                    <ThankYou />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <PageTracker />
                    <h1 className="text-center mt-10 text-2xl font-bold animate-bounce">
                      {texts[currentLanguage].pageNotFound}
                    </h1>
                  </>
                }
              />
            </Routes>
            <Footer />
          </Header>
        </div>
      </SubscriptionProvider>
    </Router>
  );
}

export default App;
