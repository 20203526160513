import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath";
import MarkdownPreview from "./MarkdownPreview";
import SEOSumarize from "./seo/SEOSumarize";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile";


const apiUrl = process.env.REACT_APP_API_URL;

const connectSummarySocket = (onResponse, onEnd) => {
  const socket = io(apiUrl, {
    reconnectionAttempts: 5,
    reconnectionDelay: 100,
    maxHttpBufferSize: 200 * 1024 * 1024,
    reconnectionDelayMax: 100,
  });

  socket.on("connect", () => {
    console.log("Summary socket connected successfully.");
  });

  socket.on("summary_response", onResponse);
  socket.on("summary_end", onEnd);

  socket.on("disconnect", () => {
    console.log("Summary socket disconnected.");
  });

  socket.on("error", (error) => {
    console.error("Summary socket error:", error);
  })

  return socket;
};


const Summary = ({ setShowNoTokenModal }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();
  const mobile = useMobile();
  const { currentLanguage } = useLanguage();
  const socketRef = useRef(null);

  const texts = {
    es: {
      uploadPrompt: "Por favor, sube un archivo o escribe algún texto antes de generar un resumen.",
      noAuthToken: "No authentication token found.",
      pdfError: "Error al extraer el texto del PDF. Por favor, prueba con otro archivo.",
      unsupportedFile: "Tipo de archivo no admitido. Sube una imagen o un archivo PDF.",
      tooLong: "El texto es demasiado extenso. Por favor, sube otro archivo más pequeño o introduce menos texto. Número de tokens excedido has subido: ",
      errorFetching: "Error pruebe nuevamente o con otro archivo más pequeño. Si el problema persiste contacte con nosotros.",
      processing: "Procesando...",
      generateSummary: "Generar Resumen",
      textPlaceholder: "Escribe aquí tu texto...",
      title: "📄 Generador de Resúmenes",
      description: "Sube un documento en PDF o varias imágenes o puedes introducir texto directamente para obtener un resumen conciso y eficiente.",
      subtitle: "Escribe un texto o sube un PDF",
      pageTitle: "Generador de Resúmenes | FotoExamen - Resúmenes Eficientes",
      pageDescription: "Genera resúmenes concisos y eficientes a partir de tus documentos o texto con FotoExamen.",
      pdfTooLong: "El PDF tiene más de 50 páginas. Por favor, sube un archivo más pequeño.",
    },
    en: {
      uploadPrompt: "Please upload a file or write some text before generating a summary.",
      noAuthToken: "No authentication token found.",
      pdfError: "Error extracting text from PDF. Please try another file.",
      unsupportedFile: "Unsupported file type. Upload an image or a PDF file.",
      tooLong: "The text is too long. Please upload a smaller file or enter less text. Tokens exceeded: ",
      errorFetching: "Error, please try again or with a smaller file. If the problem persists, contact us.",
      processing: "Processing...",
      generateSummary: "Generate Summary",
      textPlaceholder: "Write your text here...",
      title: "📄 Summary Generator",
      description: "Upload a PDF document or multiple images, or you can enter text directly to get a concise and efficient summary.",
      subtitle: "Write text or upload a PDF",
      pageTitle: "Summary Generator | PhotoExamAI - Efficient Summaries",
      pageDescription: "Generate concise and efficient summaries from your documents or text with PhotoExamAI.",
      pdfTooLong: "The PDF has more than 50 pages. Please upload a smaller file.",
    },
    it: {
      uploadPrompt: "Carica un file o scrivi del testo prima di generare un riassunto.",
      noAuthToken: "Nessun token di autenticazione trovato.",
      pdfError: "Errore durante l'estrazione del testo dal PDF. Prova con un altro file.",
      unsupportedFile: "Tipo di file non supportato. Carica un'immagine o un file PDF.",
      tooLong: "Il testo è troppo lungo. Carica un file più piccolo o inserisci meno testo. Token superati: ",
      errorFetching: "Errore, riprova o con un file più piccolo. Se il problema persiste, contattaci.",
      processing: "Elaborazione...",
      generateSummary: "Genera Riepilogo",
      textPlaceholder: "Scrivi qui il tuo testo...",
      title: "📄 Generatore di Riepiloghi",
      description: "Carica un documento PDF o più immagini, oppure puoi inserire direttamente del testo per ottenere un riassunto conciso ed efficiente.",
      subtitle: "Scrivi del testo o carica un PDF",
      pageTitle: "Generatore di Riepiloghi | RisolviCompiti - Riepiloghi Efficienti",
      pageDescription: "Genera riepiloghi concisi ed efficienti dai tuoi documenti o testo con RisolviCompiti.",
      pdfTooLong: "Il PDF ha più di 50 pagine. Per favore, carica un file più piccolo.",
    }
  };

  const content = texts[currentLanguage] || texts.es;


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    setText("");
    setSummary("");

    if (file && file.type === "application/pdf") {
      try {
        const fileData = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument(fileData).promise;
        if (pdf.numPages > 50) {
          alert(content.pdfTooLong);
          setFile(null);
          return;
        }
      } catch (error) {
        console.error("Error reading PDF:", error);
        alert(content.errorFetching);
        setFile(null);
        return;
      }
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null);
    setSummary("");
  };

  const waitForSocketConnection = (socket) => {
    return new Promise((resolve, reject) => {
      const maxAttempts = 5;
      let attempts = 0;

      const checkSocketConnection = () => {
        if (socket.connected) {
          resolve();
        } else {
          attempts += 1;
          if (attempts >= maxAttempts) {
            reject(new Error("Socket connection failed"));
          } else {
            setTimeout(checkSocketConnection, 200);
          }
        }
      };

      checkSocketConnection();
    });
  };


  const handleSummarize = async () => {
    if (!file && !text.trim()) {
      alert(content.uploadPrompt);
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error(content.noAuthToken);
      return;
    }

    if (subscriptionType === "No Subscription") {
      alert(content.noSubscription);
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById('trial_ended_modal').showModal();
      return;
    }


    socketRef.current = connectSummarySocket(
      (data) => {
        if (data?.summary_response) {
          setSummary(data.summary_response);
        }
      },
      (data) => {
        if (data?.error) {
          setErrorMessage(content.errorFetching);
        } else {
          refreshSubscription();
          setSummary(data.summary_end);
        }
        setIsLoading(false);
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
    } catch (error) {
      console.error("Error summarizing text:", error);
      setErrorMessage(content.errorFetching);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const data = {
      token: token,
      files: {},
      text: text || null
    };

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1];
         if (file.type.startsWith("image/")) {
          data.files.image = base64Data;
        } else if (file.type === "application/pdf") {
          data.files.pdf = base64Data;
        } else {
          alert(content.unsupportedFile);
          setIsLoading(false);
          return;
        }
        socketRef.current.emit("summary", data);
      };
      reader.readAsDataURL(file);
    } else {
      socketRef.current.emit("summary", data);
    }
  };

  React.useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);


  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <Helmet>
        <title>{content.pageTitle}</title>
        <meta name="description" content={content.pageDescription} />
      </Helmet>
      <CurrentPath text={content.title} />
      <div className="container max-w-4xl mx-auto p-0 mt-8 sm:p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          {content.title}
        </h1>
        <p className="mb-6">
          {content.description}
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          {content.subtitle}
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder={content.textPlaceholder}
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="file-input file-input-bordered w-full"
            />
          )}
        </div>

        <button
          onClick={handleSummarize}
          className="btn btn-primary mt-4 mb-8 w-full sm:w-auto"
        >
          {content.generateSummary}
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">{content.processing}</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {summary && (
          <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line mt-8">
            <MarkdownPreview content={summary} />
          </div>
        )}
      </div>
      {!token && !mobile && <SEOSumarize />}
    </div>
  );
};

export default Summary;
