import {
  faBars,
  faChalkboardTeacher,
  faCheckCircle,
  faClose,
  faEdit,
  faFileAlt,
  faHandshake,
  faHighlighter,
  faHistory,
  faMoneyBill,
  faPencilAlt,
  faQuestionCircle,
  faSitemap,
  faStickyNote,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ModalTrialEnded from "./ModalTrialEnded";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile";

const routes = {
  es: {
    profile: '/perfil',
    login: '/iniciar-sesion',
    prices: '/ver-precios',
    history: '/historial',
    schemes: '/crear-esquemas-con-ia',
    essay: '/crear-ensayo',
    tutors: '/tutores-ia',
    quiz: '/cuestionario-interactivo-ia',
    summary: '/crear-resumen-con-ia',
    highlight: '/subrayar-texto-con-ia',
    correctExam: '/corregir-examen-con-ia',
    notes: '/tomar-apuntes-con-ia',
    app: 'https://play.google.com/store/apps/details?id=com.fotoexamen.android',
    extension: 'https://chromewebstore.google.com/detail/fotoexamen-resuelve-tus-e/limjglkioeenlejbdikfeeecgmigbmmd',
    faq: 'https://fotoexamen.com/faq.html',
    affiliates: 'https://www.fotoexamen.com/afiliados.html',
    subscription: 'https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII',
  },
  en: {
    profile: '/profile',
    login: '/login',
    prices: '/view-prices',
    history: '/history',
    schemes: '/create-diagrams-with-ai',
    essay: '/create-essay',
    tutors: '/tutors-ai',
    quiz: '/interactive-quiz-ai',
    summary: '/create-summary-with-ai',
    highlight: '/highlight-text-with-ai',
    correctExam: '/correct-exam-with-ai',
    notes: '/note-taking-with-ai',
    app: 'https://play.google.com/store/apps/details?id=com.fotoexamen.android',
    extension: 'https://chromewebstore.google.com/detail/fotoexamen-resuelve-tus-e/limjglkioeenlejbdikfeeecgmigbmmd',
    faq: 'https://www.photoexamai.com/faq.html',
    affiliates: 'https://www.photoexamai.com/affiliates.html',
    subscription: 'https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII',
  },
  it: {
    profile: '/profilo',
    login: '/accedi', // Updated
    prices: '/vedi-prezzi', // Updated
    history: '/cronologia',
    schemes: '/creare-schemi-con-ia', // Updated
    essay: '/creare-saggio', // Updated
    tutors: '/tutori-ia', // Updated
    quiz: '/quiz-interattivo-ia',
    summary: '/creare-riassunto-con-ia', // Updated
    highlight: '/evidenziare-testo-con-ia', // Updated
    correctExam: '/correggi-esame-con-ia',
    notes: '/prendere-appunti-con-ia', // Updated
    app: 'https://play.google.com/store/apps/details?id=com.risolvicompiti.android',
    extension: 'https://chromewebstore.google.com/detail/fotoexamen-resuelve-tus-e/limjglkioeenlejbdikfeeecgmigbmmd',
    faq: 'https://www.risolvicompiti.it/faq.html',
    affiliates: 'https://www.risolvicompiti.it/affiliati.html',
    subscription: 'https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII',
  }
};

const Header = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const drawerCheckboxRef = useRef(null);
  const [token, clearToken] = useAuth();
  const {
    subscriptionType,
    remainingCredits,
    loading,
    error,
    refreshSubscription,
  } = useSubscription();
  const { currentLanguage } = useLanguage();
  const mobile = useMobile();

  const texts = {
    es: {
      subscribeNow: "¡Suscríbete ahora!",
      noCreditsLeft: "Te quedan 0 créditos",
      creditsLeft: "Te quedan {remainingCredits} crédito/s",
      myProfile: "Mi perfil",
      loginRegister: "Iniciar sesión / Registrarse",
      learnWithFotoExamen: "Aprende con FotoExamen 💙",
      resolveExam: "Resolver Examen",
      createSchemes: "Crear Esquemas",
      createEssay: "Crear Ensayo",
      tutors: "Tutores",
      createQuiz: "Crear Quiz",
      summarizeText: "Resumir Texto",
      highlightText: "Subrayar Texto",
      correctExam: "Corregir Examen",
      takeNotes: "Tomar Apuntes",
      downloadApp: "¡Descarga la APP disponible en Play Store!",
      downloadExtension: "¡Descarga la extensión para Chrome!",
      existingUser: "Si ya eres usuario, puedes usarla sin problemas con tu cuenta actual.",
      viewHistory: "Ver Historial",
      faq: "FAQ",
      viewPrices: "Ver Precios",
      affiliates: "Afiliados",
      subscription: "Suscripción",
    },
    en: {
      subscribeNow: "Subscribe now!",
      noCreditsLeft: "You have 0 credits left",
      creditsLeft: "You have {remainingCredits} credit(s) left",
      myProfile: "My Profile",
      loginRegister: "Login / Register",
      learnWithFotoExamen: "Learn with PhotoExamAI 💙",
      resolveExam: "Resolve Exam",
      createSchemes: "Create Schemes",
      createEssay: "Create Essay",
      tutors: "Tutors",
      createQuiz: "Create Quiz",
      summarizeText: "Summarize Text",
      highlightText: "Highlight Text",
      correctExam: "Correct Exam",
      takeNotes: "Take Notes",
      downloadApp: "Download the APP available on Play Store!",
      downloadExtension: "Download the Chrome extension!",
      existingUser: "If you are already a user, you can use it without problems with your current account.",
      viewHistory: "View History",
      faq: "FAQ",
      viewPrices: "View Prices",
      affiliates: "Affiliates",
      subscription: "Subscription",
    },
    it: {
      subscribeNow: "Iscriviti ora!",
      noCreditsLeft: "Non hai più crediti",
      creditsLeft: "Hai {remainingCredits} credito/i rimanente/i",
      myProfile: "Il mio profilo",
      loginRegister: "Accedi / Registrati",
      learnWithFotoExamen: "Impara con RisolviCompiti 💙",
      resolveExam: "Risolvi Compito",
      createSchemes: "Crea Schemi",
      createEssay: "Crea Saggio",
      tutors: "Tutor",
      createQuiz: "Crea Quiz",
      summarizeText: "Riassumi Testo",
      highlightText: "Evidenzia Testo",
      correctExam: "Correggi Compito",
      takeNotes: "Prendi Appunti",
      downloadApp: "Scarica l'APP disponibile su Play Store!",
      downloadExtension: "Scarica l'estensione Chrome!",
      existingUser: "Se sei già un utente, puoi usarla senza problemi con il tuo account attuale.",
      viewHistory: "Visualizza Cronologia",
      faq: "FAQ",
      viewPrices: "Vedi Prezzi",
      affiliates: "Affiliati",
      subscription: "Abbonamento",
    },
  };

  useEffect(() => {
    const savedMenuState = localStorage.getItem("isMenuOpen");
    if (savedMenuState !== null) {
      setIsMenuOpen(JSON.parse(savedMenuState));
    } else setIsMenuOpen(true);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isMenuOpen", JSON.stringify(newState));
      return newState;
    });
  };

  const closeDrawer = () => {
    if (window.innerWidth < 768) {
      setIsMenuOpen(false);
      localStorage.setItem("isMenuOpen", JSON.stringify(false));
      if (drawerCheckboxRef.current) {
        drawerCheckboxRef.current.checked = false;
      }
    }
  };

  return (
    <div className={`drawer ${isMenuOpen ? "md:drawer-open" : ""}`}>
      <input
        id="my-drawer-2"
        type="checkbox"
        className="drawer-toggle"
        ref={drawerCheckboxRef}
      />
      <div className="drawer-content flex flex-col">
        <div className="navbar bg-base-200 sticky top-0 z-50 h-10 border-b border-gray-300">
          <div className="navbar-start">
            <button
              onClick={toggleMenu}
              className="btn btn-ghost hidden md:flex"
            >
              {!isMenuOpen ? (
                <FontAwesomeIcon icon={faBars} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faClose} size="lg" />
              )}
            </button>

            <label
              htmlFor="my-drawer-2"
              className="btn btn-ghost drawer-button flex md:hidden"
            >
              <FontAwesomeIcon icon={faBars} size="lg" />
            </label>
          </div>
          <div className="navbar-end">
            {token ? (
              <>
                {subscriptionType === "No Subscription" && !window.location.pathname.includes(routes[currentLanguage].prices) ? (
                  <Link to={routes[currentLanguage].prices} className="btn btn-primary">
                    {texts[currentLanguage].subscribeNow}
                  </Link>
                ) : subscriptionType?.includes("Trial") ? (
                  remainingCredits === 0 ? (
                    <button
                      onClick={() =>
                        document.getElementById("trial_ended_modal").showModal()
                      }
                      className="btn btn-primary"
                    >
                      {texts[currentLanguage].noCreditsLeft}
                    </button>
                  ) : (
                    <span>
                      {texts[currentLanguage].creditsLeft.replace(
                        "{remainingCredits}",
                        remainingCredits
                      )}
                    </span>
                  )
                ) : null}
                <Link to={routes[currentLanguage].profile} className="btn btn-ghost">
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    size="lg"
                    className="mr-2"
                  />
                  {texts[currentLanguage].myProfile}
                </Link>
              </>
            ) : (
              <Link to={routes[currentLanguage].login} className="btn btn-ghost">
                <FontAwesomeIcon
                  icon={faUserCircle}
                  size="lg"
                  className="mr-2"
                />
                {texts[currentLanguage].loginRegister}
              </Link>
            )}
          </div>
        </div>
        <div>{children}</div>
      </div>
      <div className="drawer-side z-40 border-r-2 border-gray-200">
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
        <ul className="menu bg-base-100 text-base-content min-h-full w-80 p-4 flex justify-between">
          <div>
            <li className="font-bold text-md mt-4 flex justify-between items-center">
              <Link to="/" onClick={closeDrawer}>
                {texts[currentLanguage].learnWithFotoExamen}
              </Link>
            </li>
            <div className="divider"></div>
            <li>
              <Link to="/" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faEdit} className="mr-2" width={20} />
                {texts[currentLanguage].resolveExam}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].schemes} onClick={closeDrawer}>
                <FontAwesomeIcon icon={faSitemap} className="mr-2" width={20} />
                {texts[currentLanguage].createSchemes}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].essay} onClick={closeDrawer}>
                <FontAwesomeIcon icon={faPencilAlt} className="mr-2" width={20} />
                {texts[currentLanguage].createEssay}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].tutors} onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  className="mr-2"
                  width={20}
                />
                {texts[currentLanguage].tutors}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].quiz} onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mr-2"
                  width={20}
                />
                {texts[currentLanguage].createQuiz}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].summary} onClick={closeDrawer}>
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" width={20} />
                {texts[currentLanguage].summarizeText}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].highlight} onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faHighlighter}
                  className="mr-2"
                  width={20}
                />
                {texts[currentLanguage].highlightText}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].correctExam} onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-2"
                  width={20}
                />
                {texts[currentLanguage].correctExam}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].notes} onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faStickyNote}
                  className="mr-2"
                  width={20}
                />
                {texts[currentLanguage].takeNotes}
              </Link>
            </li>
            {currentLanguage === "es" && !mobile && (
              <>
                <Link
                  to={routes[currentLanguage].app}
                  className="alert alert-warning mb-2 mt-6 py-2 px-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 shrink-0 stroke-current"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div>
                      <h3 className="font-bold text-sm">
                        {texts[currentLanguage].downloadApp}
                      </h3>
                      <div className="text-xs">
                        {texts[currentLanguage].existingUser}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={routes[currentLanguage].extension}
                  className="alert alert-info mb-6 py-2 px-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 shrink-0 stroke-current"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div>
                      <h3 className="font-bold text-sm">
                        {texts[currentLanguage].downloadExtension}
                      </h3>
                      <div className="text-xs">
                        {texts[currentLanguage].existingUser}
                      </div>
                    </div>
                  </div>
                </Link>
              </>
            )}
          </div>
          <div>
            <div className="divider"></div>

            <li>
              <Link to={routes[currentLanguage].history} onClick={closeDrawer}>
                <FontAwesomeIcon icon={faHistory} className="mr-2" width={20} />
                {texts[currentLanguage].viewHistory}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].subscription} onClick={closeDrawer} target="_blank">
                <FontAwesomeIcon icon={faMoneyBill} className="mr-2" width={20} />
                {texts[currentLanguage].subscription}
              </Link>
            </li>
            <li>
              <Link
                to={routes[currentLanguage].faq}
                target="_blank"
                onClick={closeDrawer}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mr-2"
                  width={20}
                />
                {texts[currentLanguage].faq}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].prices}>
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  className="mr-2"
                  width={20}
                />
                {texts[currentLanguage].viewPrices}
              </Link>
            </li>
            <li>
              <Link to={routes[currentLanguage].affiliates} onClick={closeDrawer} target="_blank">
                <FontAwesomeIcon
                  icon={faHandshake}
                  className="mr-2"
                  width={20}
                />
                {texts[currentLanguage].affiliates}
              </Link>
            </li>
            <li>
              {token ? (
                <Link to={routes[currentLanguage].profile} onClick={closeDrawer}>
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    className="mr-2"
                    width={20}
                  />
                  {texts[currentLanguage].myProfile}
                </Link>
              ) : (
                <Link to={routes[currentLanguage].login} onClick={closeDrawer}>
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    size="lg"
                    className="mr-2"
                  />
                  {texts[currentLanguage].loginRegister}
                </Link>
              )}
            </li>
          </div>
        </ul>
      </div>
      <ModalTrialEnded />
    </div>
  );
};

export default Header;
