import html2canvas from "html2canvas-pro";
import { jsPDF } from "jspdf";
import React, { useRef } from "react";
import { FaFilePdf } from "react-icons/fa";
import { BlockMath, InlineMath } from "react-katex";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import rehypeSanitize from "rehype-sanitize";
import remarkMath from "remark-math";
import { useLanguage } from "./useLanguaje";

const texts = {
  en: {
    downloadPDFButton: "Download Solution",
  },
  es: {
    downloadPDFButton: "Descargar Solución",
  },
  it: {
    downloadPDFButton: "Scarica Soluzione",
  },
};

const MarkdownPreview = ({ content }) => {
  const printRef = useRef();
  const { currentLanguage } = useLanguage();
  const isMobile = () => {
    return /Android/i.test(navigator.userAgent);
  };

  const handleDownload = () => {
    if (isMobile()) {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const content = printRef.current;
      const options = {
        callback: function(doc) {
          doc.save('solution.pdf');
        },
        x: 15,
        y: 15,
        width: 180,
        windowWidth: content.scrollWidth
      };

      html2canvas(content, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        logging: true,
        width: content.scrollWidth,
        height: content.scrollHeight,
        windowWidth: content.scrollWidth,
        windowHeight: content.scrollHeight,
        scrollY: -window.scrollY
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pageWidth = doc.internal.pageSize.getWidth() - 20;
        const pageHeight = doc.internal.pageSize.getHeight() - 20;
        
        let yPos = 0;
        let remainingHeight = canvas.height;
        
        while (remainingHeight > 0) {
          const sliceHeight = Math.min(
            remainingHeight, 
            (pageHeight * canvas.width) / pageWidth * 0.90
          );
          
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = canvas.width;
          tempCanvas.height = sliceHeight;
          
          const ctx = tempCanvas.getContext('2d');
          ctx.drawImage(canvas, 0, yPos, canvas.width, sliceHeight, 0, 0, canvas.width, sliceHeight);
          
          const sliceData = tempCanvas.toDataURL('image/png');
          const sliceRatio = tempCanvas.width / tempCanvas.height;
          
          let imgWidth = pageWidth;
          let imgHeight = imgWidth / sliceRatio;
          
          const yOffset = yPos > 0 ? 10 : 10;
          
          if (yPos > 0) {
            doc.addPage();
          }
          
          if (remainingHeight - sliceHeight <= 0) {
            imgHeight = Math.min(imgHeight, pageHeight - yOffset);
          }
          
          doc.addImage(sliceData, 'PNG', 10, yOffset, imgWidth, imgHeight);
          
          yPos += sliceHeight;
          remainingHeight -= sliceHeight;
        }
        
        doc.save('solution.pdf');
      });

    } else {
      const printContent = printRef.current;
      const originalContent = document.body.innerHTML;

      const printDiv = document.createElement("div");
      const katexStyles = document.createElement("link");
      katexStyles.rel = "stylesheet";
      katexStyles.href =
        "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css";

      printDiv.appendChild(katexStyles);
      printDiv.appendChild(printContent.cloneNode(true));

      document.body.innerHTML = "";
      document.body.appendChild(printDiv);

      katexStyles.onload = () => {
        window.print();
        document.body.innerHTML = originalContent;
      };
    }
  };

  return (
    <div>
      <div className="text-center mt-4">
        <button onClick={handleDownload} className="btn btn-primary mb-4">
          <FaFilePdf size={24} className="mr-2" /> {texts[currentLanguage].downloadPDFButton}
        </button>
      </div>
      <div ref={printRef} className="whitespace-pre-line">
        <ReactMarkdown
          rehypePlugins={[rehypeSanitize, rehypeKatex]}
          remarkPlugins={[remarkMath]}
          components={{
            math: ({ value }) => <BlockMath math={value} />,
            inlineMath: ({ value }) => <InlineMath math={value} />,
          }}
          disallowedElements={["pre"]}
          unwrapDisallowed
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default MarkdownPreview;
